import {Line} from 'react-chartjs-2';
import {Chart as ChartJS, LineController, LineElement, PointElement, LinearScale, Title, CategoryScale} from 'chart.js';

ChartJS.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale);

const CashFlowChart = ({data}) => {
    const labels = data.map((entry) => `${entry.month}`);
    const monthlyCashFlow = data.map((entry) => entry.netMonthlyCashFlow);
    const cumulativeCashFlow = data.map((entry) => entry.cumulativeCashFlow);

    const chartData = {
        labels: labels,
        datasets: [
            {
                label: 'Cumulative Cash Flow',
                // labelColor: '#00CC66',
                data: cumulativeCashFlow,
                borderColor: cumulativeCashFlow[cumulativeCashFlow.length - 1] > 0 ? '#00CC66' : '#FF4136',
                backgroundColor: cumulativeCashFlow[cumulativeCashFlow.length - 1] > 0 ? '#00CC66' : '#FF4136',
                borderWidth: 1,
                yAxisID: 'y-cumulative-cash-flow',
            },
            {
                label: 'Monthly Cash Flow',
                // labelColor: '#00CC66',
                data: monthlyCashFlow,
                // borderColor: cumulativeCashFlow[cumulativeCashFlow.length - 1] > 0 ? '#00CC66' : '#FF4136',
                // backgroundColor: cumulativeCashFlow[cumulativeCashFlow.length - 1] > 0 ? '#00CC66' : '#FF4136',
                backgroundColor: '#0074D9',
                borderColor: '#0074D9',
                // fill: true,
                // borderWidth: 1,
                yAxisID: 'y-monthly-cash-flow',
            },
        ],
    };

    const options = {
        scales: {
            'y-monthly-cash-flow': {
                title: {
                    display: true,
                    text: 'Monthly cash flow',
                    color: '#0074D9',
                },
                position: 'right',
                ticks: {
                    color: '#0074D9',
                    callback: function (value, index, ticks) {
                        return 'R' + value;
                    },
                },
            },
            'y-cumulative-cash-flow': {
                // beginAtZero: false,
                position: 'left',
                ticks: {
                    color: cumulativeCashFlow[cumulativeCashFlow.length - 1] > 0 ? '#00CC66' : '#FF4136',
                },
                title: {
                    display: true,
                    text: 'Cumulative Cash Flow',
                    color: cumulativeCashFlow[cumulativeCashFlow.length - 1] > 0 ? '#00CC66' : '#FF4136',
                    font: {
                        size: 16,
                    },
                },
            },
            x: {
                ticks: {
                    // color: '#e8e8e8',
                },
                title: {
                    display: true,
                    text: 'Month',
                    // color: '#e8e8e8',
                    font: {
                        size: 16,
                    },
                },
            },
        },
    };

    return <Line data={chartData} options={options} />;
};

export default CashFlowChart;
