import React from 'react';
import sampleProperties from '../sample-properties';

const UseExampleValues = () => {
    return (
        <>
            {sampleProperties.map((property) => (
                <div key={property.link} className={'use-example-values-container'}>
                    <span>
                        <a href={property.link}>
                            <img src={property.icon || '/favicon.png'} width={20} alt={'Property icon'} />
                            &nbsp; {property.name}
                        </a>
                    </span>
                </div>
            ))}
        </>
    );
};

export default UseExampleValues;
