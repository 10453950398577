import React from 'react';
import {useForm} from 'react-hook-form';

const AddAdditionalPayment = ({onSubmit}) => {
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm();

    return (
        <div className={''}>
            <form
                onSubmit={handleSubmit((data) => {
                    onSubmit({
                        amount: data.amount,
                    });
                })}
            >
                <div className={'container'}>
                    <div className="input-group">
                        <label htmlFor="amount">Amount*</label>
                        <input
                            autoFocus
                            type="number"
                            id="amount"
                            name="amount"
                            min="0"
                            step="any"
                            {...register('amount', {
                                required: true,
                                valueAsNumber: true,
                            })}
                        />
                        <span className={'mx-10'}>{errors.amount?.type === 'required' && <small>required</small>}</span>
                    </div>
                    <div className={'input-group'}>
                        <button className={'primary'} type={'submit'}>
                            Add monthly payment
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default AddAdditionalPayment;
