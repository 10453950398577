import React from 'react';
import './MonthlySummary.css';
import {NumericFormat as NumberFormat} from 'react-number-format';

const MonthlySummary = ({data, expanded, toggleExpand}) => {
    const netMonthlyCashFlowClass = data.netMonthlyCashFlow >= 0 ? 'positive' : 'negative';
    const cumulativeCashFlowClass = data.cumulativeCashFlow >= 0 ? 'positive' : 'negative';

    return (
        <div className="monthly-summary-card">
            <h2>After Month {data.month}</h2>
            <div className={`monthly-summary-info`}>
                <div className={'cash-flows'}>
                    <div className={'cash-flow'}>
                        <div className={`highlighted ${netMonthlyCashFlowClass}`}>Net Monthly Cash Flow</div>
                        <div className={`amount ${netMonthlyCashFlowClass}`}>
                            <NumberFormat
                                value={data.netMonthlyCashFlow.toFixed(2)}
                                className={`amount ${netMonthlyCashFlowClass}`}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'R'}
                                renderText={(value, props) => <div {...props}>{value}</div>}
                            />
                        </div>
                    </div>
                    <div className={'cash-flow'}>
                        <div className={`highlighted ${cumulativeCashFlowClass}`}>Cumulative Cash Flow</div>
                        <div className={`amount ${cumulativeCashFlowClass}`}>
                            {/*{data.cumulativeCashFlow.toFixed(2)}*/}
                            <NumberFormat
                                value={data.cumulativeCashFlow.toFixed(2)}
                                className={`amount ${cumulativeCashFlowClass}`}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'R'}
                                renderText={(value, props) => <div {...props}>{value}</div>}
                            />
                        </div>
                    </div>
                </div>
                {expanded && (
                    <div className={'additional-info'}>
                        <div className={`highlighted`}>
                            Rental Income:{' '}
                            <NumberFormat
                                value={data.rentalIncome.toFixed(2)}
                                className={`amount`}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'R'}
                                renderText={(value, props) => <span {...props}>{value}</span>}
                            />
                        </div>
                        <div className={`highlighted`}>
                            Levies:{' '}
                            <NumberFormat
                                value={data.levies.toFixed(2)}
                                className={`amount`}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'R'}
                                renderText={(value, props) => <span {...props}>{value}</span>}
                            />
                        </div>
                        <div className={`highlighted`}>
                            Rates & Taxes:{' '}
                            <NumberFormat
                                value={data.ratesAndTaxes.toFixed(2)}
                                className={`amount`}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'R'}
                                renderText={(value, props) => <span {...props}>{value}</span>}
                            />
                        </div>
                        <div className={`highlighted`}>
                            Remaining Principal:{' '}
                            <NumberFormat
                                value={data.remainingPrincipal.toFixed(2)}
                                className={`amount`}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'R'}
                                renderText={(value, props) => <span {...props}>{value}</span>}
                            />
                        </div>
                    </div>
                )}
                {!expanded && (
                    <div className={'toggle-expand centered'} onClick={toggleExpand}>
                        {' '}
                        +{' '}
                    </div>
                )}
                {expanded && (
                    <div className={'toggle-expand'} onClick={toggleExpand}>
                        {' '}
                        -{' '}
                    </div>
                )}
            </div>
        </div>
    );
};

export default MonthlySummary;
