const modalCustomStyles = {
    overlay: {
        backgroundColor: 'rgba(0,0,0, 0.3)',
        backdropFilter: 'blur(50px)',
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#1A2B3C',
        borderRadius: 10,
        width: '300px',
    },
};

export default modalCustomStyles;
