import {NumericFormat as NumberFormat} from 'react-number-format';
import React from 'react';

const UpfrontCosts = ({bondAndTransferFees, expanded, toggleExpand}) => {
    return (
        <div className={'transfer-and-bond-fees'}>
            <div
                className=""
                style={{
                    flex: 1,
                }}
            >
                {/*<h2>Transfer Fees</h2>*/}
                <div className={`monthly-summary-info`}>
                    <div className={'cash-flows'}>
                        <div className={'cash-flow'}>
                            <div className={'mb-10'}>
                                <div className={`highlighted`}>Total transfer fees:</div>
                                <div>
                                    <NumberFormat
                                        value={bondAndTransferFees.transferTotalCosts.toFixed(2)}
                                        className={`amount`}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'R'}
                                        renderText={(value, props) => <span {...props}>{value}</span>}
                                    />
                                </div>
                            </div>
                            <div className={'additional-info'}>
                                <div className={`highlighted`}>
                                    Transfer costs:{' '}
                                    <NumberFormat
                                        value={bondAndTransferFees.transferFees.toFixed(2)}
                                        // className={`amount`}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'R'}
                                        renderText={(value, props) => <span {...props}>{value}</span>}
                                    />
                                </div>
                                <div className={`highlighted`}>
                                    Transfer Duty:{' '}
                                    <NumberFormat
                                        value={bondAndTransferFees.transferDuty.toFixed(2)}
                                        // className={`amount`}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'R'}
                                        renderText={(value, props) => <span {...props}>{value}</span>}
                                    />
                                </div>
                                <div className={`highlighted`}>
                                    Deeds office levies:{' '}
                                    <NumberFormat
                                        value={bondAndTransferFees.transferDeedOfficeLevies.toFixed(2)}
                                        // className={`amount`}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'R'}
                                        renderText={(value, props) => <span {...props}>{value}</span>}
                                    />
                                </div>
                                <div className={`highlighted `}>
                                    Post, petties, etc:{' '}
                                    <NumberFormat
                                        value={bondAndTransferFees.transferMisc.toFixed(2)}
                                        // className={`amount`}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'R'}
                                        renderText={(value, props) => <span {...props}>{value}</span>}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="">
                {/*<h2>Bond Fees</h2>*/}
                <div className="monthly-summary-info">
                    <div className={'cash-flows'}>
                        <div className={'cash-flow'}>
                            <div className={'mb-10'}>
                                <div className={`highlighted`}>Total bond fees:</div>
                                <div>
                                    <NumberFormat
                                        value={bondAndTransferFees.bondTotalCosts.toFixed(2)}
                                        className={`amount`}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'R'}
                                        renderText={(value, props) => <span {...props}>{value}</span>}
                                    />
                                </div>
                            </div>
                            <div className={'additional-info'}>
                                <div className={`highlighted`}>
                                    Bond registration:{' '}
                                    <NumberFormat
                                        value={bondAndTransferFees.bondFees.toFixed(2)}
                                        // className={`amount`}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'R'}
                                        renderText={(value, props) => <span {...props}>{value}</span>}
                                    />
                                </div>
                                <div className={`highlighted`}>
                                    Bond initiation fee:{' '}
                                    <NumberFormat
                                        value={bondAndTransferFees.bondBankInitiationFee.toFixed(2)}
                                        // className={`amount`}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'R'}
                                        renderText={(value, props) => <span {...props}>{value}</span>}
                                    />
                                </div>
                                <div className={`highlighted`}>
                                    Deeds office levies:{' '}
                                    <NumberFormat
                                        value={bondAndTransferFees.bondDeedOfficeLevies.toFixed(2)}
                                        // className={`amount`}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'R'}
                                        renderText={(value, props) => <span {...props}>{value}</span>}
                                    />
                                </div>
                                <div className={`highlighted `}>
                                    Misc (post, petties, etc.):{' '}
                                    <NumberFormat
                                        value={bondAndTransferFees.bondMisc.toFixed(2)}
                                        // className={`amount`}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'R'}
                                        renderText={(value, props) => <span {...props}>{value}</span>}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default UpfrontCosts;
