import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {differenceInMonths} from 'date-fns';

const AddLumpSumPayment = ({onSubmit}) => {
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm();

    const years = [];
    let now = new Date();
    for (let i = now.getFullYear(); i < now.getFullYear() + 20; i++) {
        years.push(i);
    }
    const [selectedYear, setSelectedYear] = useState(now.getMonth() < 11 ? years[0] : years[1]);

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    return (
        <div className={''}>
            <form
                onSubmit={handleSubmit((data) => {
                    const dateOfPayment = new Date(data.year, data.month, 1);
                    const monthsFromToday = differenceInMonths(dateOfPayment, now) + 1;
                    onSubmit({
                        amount: data.amount,
                        month: monthsFromToday,
                    });
                })}
            >
                <div className={'container'}>
                    <div className="input-group">
                        <label htmlFor="amount">Amount*</label>
                        <input
                            autoFocus
                            type="number"
                            id="amount"
                            name="amount"
                            min="0"
                            step="any"
                            {...register('amount', {
                                required: true,
                                valueAsNumber: true,
                            })}
                        />
                        <span className={'mx-10'}>{errors.amount?.type === 'required' && <small>required</small>}</span>
                    </div>
                    <div className="input-group">
                        <label htmlFor="year">Year of payment*</label>
                        <select
                            id="year"
                            name="year"
                            {...register('year', {
                                required: true,
                                valueAsNumber: true,
                                onChange: (e) => {
                                    setSelectedYear(e.target.value);
                                },
                            })}
                        >
                            <option disabled={now.getMonth() === 11 /* 11 for December*/} value={years[0]}>
                                {years[0]} (This year)
                            </option>{' '}
                            <option value={years[1]}>{years[1]} (Next year)</option>
                            {years.slice(2).map((y, index) => (
                                <option value={y}>
                                    {y} (In {`${index + 2} years`})
                                </option>
                            ))}
                        </select>
                        <span className={'mx-10'}>{errors.amount?.type === 'required' && <small>required</small>}</span>
                    </div>
                    <div className="input-group">
                        <label htmlFor="month">Month of payment*</label>
                        <select
                            id="month"
                            name="month"
                            {...register('month', {
                                required: true,
                                valueAsNumber: true,
                            })}
                        >
                            {months.map((m, index) => (
                                <option disabled={selectedYear === now.getFullYear() && index <= now.getMonth()} value={index}>
                                    {m}
                                </option>
                            ))}
                        </select>
                        <span className={'mx-10'}>{errors.amount?.type === 'required' && <small>required</small>}</span>
                    </div>
                    <div className={'input-group'}>
                        <button className={'primary'} type={'submit'}>
                            Make lump sum payment
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default AddLumpSumPayment;
