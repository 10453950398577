import {NumericFormat as NumberFormat} from 'react-number-format';
import React from 'react';

const AmortizationSchedule = ({schedule}) => {
    return (
        <div className="amortization-wrapper">
            <table id="amortizationTable">
                <thead>
                    <tr>
                        <th>Month</th>
                        <th className={'mobile-hide'}>Bond repayment + fees</th>
                        <th className={'mobile-hide'}>Levies</th>
                        <th className={'mobile-hide'}>Rates</th>
                        <th className={'mobile-hide'}>Additional payment</th>
                        <th className={'mobile-hide'}>Principal</th>
                        <th className={'mobile-hide'}>Interest</th>
                        <th className={'mobile-hide'}>Bond balance</th>
                        <th>Net monthly cash flow</th>
                        <th>Cumulative cash flow</th>
                    </tr>
                </thead>
                <tbody style={{textAlign: 'right'}}>
                    {schedule.map((row, index) => {
                        return (
                            <tr key={row.month}>
                                <td>{row.month}</td>
                                <td className={'mobile-hide'}>
                                    <NumberFormat
                                        value={row.debtRepaymentAndFees.toFixed(2)}
                                        className={`amount`}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'R'}
                                        renderText={(value, props) => <span {...props}>{value}</span>}
                                    />
                                </td>
                                <td className={'mobile-hide'}>
                                    {' '}
                                    <NumberFormat
                                        value={row.levies.toFixed(2)}
                                        className={`amount`}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'R'}
                                        renderText={(value, props) => <span {...props}>{value}</span>}
                                    />
                                </td>
                                <td className={'mobile-hide'}>
                                    {' '}
                                    <NumberFormat
                                        value={row.ratesAndTaxes.toFixed(2)}
                                        className={`amount`}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'R'}
                                        renderText={(value, props) => <span {...props}>{value}</span>}
                                    />
                                </td>
                                <td className={'mobile-hide'}>
                                    {' '}
                                    <NumberFormat
                                        value={row.additionalPayment.toFixed(2)}
                                        className={`amount`}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'R'}
                                        renderText={(value, props) => <span {...props}>{value}</span>}
                                    />
                                </td>
                                <td className={'mobile-hide'}>
                                    {' '}
                                    <NumberFormat
                                        value={row.principalPayment.toFixed(2)}
                                        className={`amount`}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'R'}
                                        renderText={(value, props) => <span {...props}>{value}</span>}
                                    />
                                </td>
                                <td className={'mobile-hide'}>
                                    {' '}
                                    <NumberFormat
                                        value={row.interestPayment.toFixed(2)}
                                        className={`amount`}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'R'}
                                        renderText={(value, props) => <span {...props}>{value}</span>}
                                    />
                                </td>
                                <td className={'mobile-hide'}>
                                    {' '}
                                    <NumberFormat
                                        value={row.remainingPrincipal.toFixed(2)}
                                        className={`amount`}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'R'}
                                        renderText={(value, props) => <span {...props}>{value}</span>}
                                    />
                                </td>
                                <td className={row.netMonthlyCashFlow >= 0 ? 'positive' : 'negative'}>
                                    {' '}
                                    <NumberFormat
                                        value={row.netMonthlyCashFlow.toFixed(2)}
                                        className={`amount`}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'R'}
                                        renderText={(value, props) => <strong {...props}>{value}</strong>}
                                    />
                                </td>
                                <td className={row.cumulativeCashFlow >= 0 ? 'positive' : 'negative'}>
                                    {' '}
                                    <NumberFormat
                                        value={row.cumulativeCashFlow.toFixed(2)}
                                        className={`amount`}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'R'}
                                        renderText={(value, props) => <strong {...props}>{value}</strong>}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};
export default AmortizationSchedule;
