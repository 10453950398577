import React from 'react';
import {render, hydrate} from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = document.getElementById('root');

if (root.hasChildNodes()) {
    hydrate(
        <React.StrictMode>
            <App />
        </React.StrictMode>,
        root
    );
} else {
    render(
        <React.StrictMode>
            <App />
        </React.StrictMode>,
        root
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
