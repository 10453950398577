import {useNavigate} from 'react-router-dom';
import sampleProperties from '../sample-properties';

function Landing() {
    const navigate = useNavigate();
    const midrandPropertyLink = sampleProperties.find((p) => p.id === 'midrand').link;
    const capeTownPropertyLink = sampleProperties.find((p) => p.id === 'capeTown').link;

    function navigateTo(link) {
        console.log(link);
        navigate(link);
    }

    return (
        <div className={'landing-container'}>
            <div className={'mt-50'}>
                <h3 className={'text-center mb-2'}>What would you like to do? </h3>
            </div>
            <div className="property-card mb-10 pointer" onClick={() => navigateTo(midrandPropertyLink)}>
                <div className="card-content">
                    <div className="image-container">
                        {/*<img src="https://images.prop24.com/327172783/Crop600x400" alt="Property in Midrand" />*/}
                        <img src="/midrand-prop.jpeg" alt="Property in Midrand" />
                    </div>
                    <div className="text-container">
                        <div className="card-description">Analyse example</div>
                        <div className="card-title">1 Bed Apartment in Midrand</div>
                    </div>
                </div>
            </div>
            <div className="property-card mb-10 pointer" onClick={() => navigateTo(capeTownPropertyLink)}>
                <div className="card-content">
                    <div className="image-container">
                        <img
                            // src="https://img.jamesedition.com/listing_images/2023/06/29/12/56/57/0675e124-4c8f-4060-8ad0-8ba6b5556009/je/760xxsxm.jpg"
                            src="/ct-prop.jpg"
                            alt={'Property in Cape Town'}
                        />
                    </div>
                    <div className="text-container">
                        <div className="card-description">Analyse example</div>
                        <div className="card-title">1 Bed Apartment in Cape Town</div>
                    </div>
                </div>
            </div>
            <div className="property-card mb-10 pointer " onClick={() => navigateTo('/')}>
                <div className="card-content gold-bg text-dark-blue">
                    <div className="image-container">
                        <img src="/mansion2.jpg" alt={'Property in Cape Town'} />
                    </div>
                    <div className="text-container">
                        <div>
                            <img src="/crown.svg" width={35} alt={''} />
                        </div>
                        <div className="card-title">Analyse your own rental property</div>
                        {/*<div className="card-description">1 bedroom apartment in Cape Town</div>*/}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Landing;
