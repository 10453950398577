import React from 'react';

const sampleProperties = [
    {
        name: (
            <span>
                How profitable is a 1 bedroom apartment in <strong>Midrand</strong>
            </span>
        ),
        id: 'midrand',
        resultsHeader: 'Analysis for 1 bedroom apartment in Midrand',
        icon: '/favicon.png',
        link: '/e/1-bedroom-apartment-in-midrand-profit',
        values: {
            defaultPurchasePrice: 600_000,
            defaultDepositPercent: 10,
            defaultPeriodInMonths: 240,
            defaultInitialLevies: 1_400.07,
            defaultInitialRatesAndTaxes: 464.88,
            defaultInitialRentalIncome: 6300,
            defaultAnnualRentalEscalationPercent: 3,
        },
    },
    {
        name: (
            <span>
                How profitable is a 2 bedroom apartment in <strong>Cape Town</strong>
            </span>
        ),
        id: 'capeTown',
        resultsHeader: 'Analysis for 1 bedroom apartment in Cape Town',
        icon: '/wave.png',
        link: '/e/2-bedroom-apartment-in-cape-town-profit',
        values: {
            defaultPurchasePrice: 1_850_000,
            defaultDepositPercent: 10,
            defaultPeriodInMonths: 240,
            defaultInitialLevies: 1800,
            defaultInitialRatesAndTaxes: 1000,
            defaultInitialRentalIncome: 14_000,
            defaultAnnualRentalEscalationPercent: 8,
        },
    },
];

export default sampleProperties;
