import React from 'react';
import {NumericFormat as NumberFormat} from 'react-number-format';
import UpfrontCosts from './UpfrontCosts';

const HeadlineSummary = ({data}) => {
    return (
        <div
            className={'card background-black cash-flow m-10-mobile'}
            style={{
                fontSize: 14,
                display: 'flex',
                flex: 1,
            }}
        >
            <div className={'mb-10'}>
                <div className={'highlighted'}>Purchase price </div>
                <NumberFormat
                    value={data.purchasePrice}
                    className={`amount`}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'R'}
                    renderText={(value, props) => <span {...props}>{value}</span>}
                />
            </div>

            <div className={'mb-10'}>
                <div className={'highlighted'}>Deposit </div>
                <NumberFormat
                    value={data.purchasePrice - data.principal}
                    className={`amount`}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'R'}
                    renderText={(value, props) => <span {...props}>{value}</span>}
                />
            </div>

            <div className={'mb-10'}>
                <div className={'highlighted'}>Loan principal </div>
                <NumberFormat
                    value={data.principal}
                    className={`amount`}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'R'}
                    renderText={(value, props) => <span {...props}>{value}</span>}
                />
            </div>
            <div className={'mb-20'}>
                <div className={'highlighted'}>Total monthly repayment (Inc. service fee)</div>
                <NumberFormat
                    value={data.totalMonthlyPayment}
                    className={`amount`}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'R'}
                    renderText={(value, props) => <span {...props}>{value}</span>}
                />
            </div>
            <UpfrontCosts bondAndTransferFees={data.bondAndTransferFees} />

            {/*<div className={''}>*/}
            {/*    Bond repayment:{' '}*/}
            {/*    <NumberFormat*/}
            {/*        value={data.monthlyDebtRepayment}*/}
            {/*        // className={`amount`}*/}
            {/*        displayType={'text'}*/}
            {/*        thousandSeparator={true}*/}
            {/*        prefix={'R'}*/}
            {/*        renderText={(value, props) => <span {...props}>{value}</span>}*/}
            {/*    />*/}
            {/*</div>*/}
            {/*<div className={''}>*/}
            {/*    Bond service fee:{' '}*/}
            {/*    <NumberFormat*/}
            {/*        value={data.monthlyServiceFee}*/}
            {/*        // className={`amount`}*/}
            {/*        displayType={'text'}*/}
            {/*        thousandSeparator={true}*/}
            {/*        prefix={'R'}*/}
            {/*        renderText={(value, props) => <span {...props}>{value}</span>}*/}
            {/*    />*/}
            {/*</div>*/}
        </div>
    );
};
export default HeadlineSummary;
