import {useForm} from 'react-hook-form';
import React, {useEffect, useRef, useState} from 'react';
import Modal from 'react-modal';
import UseExampleValues from './UseExampleValues';
import AddAdditionalPayment from './AddAdditionalPayment';
import {NumericFormat as NumberFormat} from 'react-number-format';
import HeadlineSummary from './HeadlineSummary';
import MonthlySummary from './MonthlySummary';
import CashflowChart from './CashflowChart';
import AmortizationSchedule from './AmortizationSchedule';
import customStyles from '../modalCustomStyles';
import AddLumpSumPayment from './AddLumpSumPayment';
const primeRate = 11.75;

function Calculator({defaultValues = {}, isMobileDevice = false, calculateImmediately = false, resultsHeader = ''}) {
    const {
        register,
        handleSubmit,
        formState: {errors},
        setValue,
    } = useForm();

    const [analysisResult, setAnalysisResult] = useState(null);
    const [expandMilestoneCards, setExpandMilestoneCards] = useState(true);
    const [additionalPayments, setAdditionalPayments] = useState([]);
    const [lumpSumPayments, setLumpSumPayments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [lastMonthSummary, setLastMonthSummary] = useState({});
    const insightsRef = useRef(null);

    // eslint-disable react-hooks/exhaustive-deps
    useEffect(() => {
        handleSubmit(onSubmit)();
    }, [additionalPayments, lumpSumPayments]);

    useEffect(() => {
        if (calculateImmediately) {
            setFormValuesAndCalculate({});
        }
    }, []);

    const [additionalMonthlyPaymentModalIsOpen, setAdditionalMonthlyPaymentModalIsOpen] = React.useState(false);
    const [lumpSumPaymentModalIsOpen, setLumpSumPaymentModalIsOpen] = React.useState(false);

    const {
        defaultPurchasePrice = null,
        defaultDepositPercent = null,
        defaultPeriodInMonths = null,
        defaultInitialLevies = null,
        defaultInitialRatesAndTaxes = null,
        defaultInitialRentalIncome = null,
        defaultAnnualRentalEscalationPercent = null,
    } = defaultValues;

    function setFormValuesAndCalculate(values) {
        setValue('purchasePrice', defaultPurchasePrice || 600_000);
        setValue('depositPercent', defaultDepositPercent || 10);
        setValue('interestRate', primeRate);
        setValue('periodInMonths', defaultPeriodInMonths || 240);
        setValue('initialLevies', defaultInitialLevies || 1323);
        setValue('initialRatesAndTaxes', defaultInitialRatesAndTaxes || 387);
        setValue('initialRentalIncome', defaultInitialRentalIncome || 6500);
        setValue('annualRentalEscalationPercent', defaultAnnualRentalEscalationPercent || 3);
        setTimeout(() => handleSubmit(onSubmit)(), 0);
    }

    function openAdditionalMonthlyPaymentsModal() {
        setAdditionalMonthlyPaymentModalIsOpen(true);
    }

    function openLumpSumPaymentsModal() {
        setLumpSumPaymentModalIsOpen(true);
    }

    function closeAdditionalMonthlyPaymentsModal() {
        setAdditionalMonthlyPaymentModalIsOpen(false);
    }

    function closeLumpSumPaymentsModal() {
        setLumpSumPaymentModalIsOpen(false);
    }

    function handleAdditionalPayment(data) {
        setAdditionalPayments([...additionalPayments, data]);
        closeAdditionalMonthlyPaymentsModal();
    }

    function handleLumpSumPayment(data) {
        setLumpSumPayments(JSON.parse(JSON.stringify([...lumpSumPayments, data])).sort((a, b) => a.month - b.month));

        closeLumpSumPaymentsModal();
    }

    async function onSubmit(data) {
        try {
            setLoading(true);
            const postBody = {
                ...data,
                principal: data.purchasePrice * (1 - data.depositPercent / 100),
                recurringAdditionalPayments: additionalPayments,
                additionalPayments: lumpSumPayments,
            };
            delete postBody.depositPercent;
            const response = await fetch(process.env.REACT_APP_API_BASE + '/analyze', {
                method: 'POST',
                body: JSON.stringify(postBody),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const analysisResponse = await response.json();
            // console.log(analysisResponse);
            setLoading(false);
            setAnalysisResult(analysisResponse);
            setLastMonthSummary(analysisResponse.milestoneSnapShots[analysisResponse.milestoneSnapShots.length - 1]);

            if (isMobileDevice) {
                setTimeout(() => insightsRef.current.scrollIntoView({behavior: 'smooth'}), 0);
            }
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    }
    Modal.setAppElement('#root');

    return (
        <div className="App">
            <div className="above-the-fold">
                <div className={'mobile-show'}>
                    <UseExampleValues />
                </div>
                <form className={`main-form ${loading && isMobileDevice ? 'mobile-hide' : ''}`} onSubmit={handleSubmit(onSubmit)}>
                    <div className="input-group">
                        <label htmlFor="purchasePrice">Purchase Price*:</label>
                        <input
                            autoFocus
                            type="number"
                            id="purchasePrice"
                            name="purchasePrice"
                            min="1"
                            step="any"
                            // defaultValue={5990000}
                            {...register('purchasePrice', {
                                required: true,
                                valueAsNumber: true,
                            })}
                        />
                        {/*<span>{errors.principal?.type === 'required' && <small>required</small>}</span>*/}
                    </div>

                    <div className="input-group">
                        <label htmlFor="depositPercent">Deposit (%):</label>
                        <input
                            type="number"
                            id="depositPercent"
                            name="depositPercent"
                            min="0"
                            max="100"
                            step="any"
                            defaultValue={0}
                            {...register('depositPercent', {
                                valueAsNumber: true,
                            })}
                        />
                        {/*<span>{errors.de?.type === 'required' && <small>required</small>}</span>*/}
                    </div>

                    <div className="input-group">
                        <label htmlFor="interestRate">Interest rate (%)*:</label>
                        <input
                            type="number"
                            id="interestRate"
                            name="interestRate"
                            min="0"
                            step="0.01"
                            defaultValue={primeRate}
                            {...register('interestRate', {
                                required: true,
                                valueAsNumber: true,
                            })}
                        />
                        <span>{errors.interestRate?.type === 'required' && <small>required</small>}</span>
                    </div>

                    <div className="input-group">
                        <label htmlFor="periodInMonths">Loan term (months)*:</label>
                        <input
                            type="number"
                            id="periodInMonths"
                            name="periodInMonths"
                            min="1"
                            step="any"
                            defaultValue={240}
                            {...register('periodInMonths', {
                                required: true,
                                valueAsNumber: true,
                            })}
                        />
                        <span>{errors.periodInMonths?.type === 'required' && <small>required</small>}</span>
                    </div>

                    <div className="input-group">
                        <label htmlFor="initialLevies">Levies:</label>
                        <input
                            type="number"
                            id="initialLevies"
                            name="initialLevies"
                            min="0"
                            step="any"
                            defaultValue={0}
                            {...register('initialLevies', {
                                valueAsNumber: true,
                            })}
                        />
                    </div>

                    <div className="input-group">
                        <label htmlFor="initialRatesAndTaxes">Rates and taxes</label>
                        <input
                            type="number"
                            id="initialRatesAndTaxes"
                            name="initialRatesAndTaxes"
                            min="0"
                            step="any"
                            defaultValue={0}
                            {...register('initialRatesAndTaxes', {
                                // required: true,
                                valueAsNumber: true,
                            })}
                        />
                        <span>{errors.initialRatesAndTaxes?.type === 'required' && <small>required</small>}</span>
                    </div>

                    <div className="input-group">
                        <label htmlFor="initialRentalIncome">Rental income:</label>
                        <input
                            type="number"
                            id="initialRentalIncome"
                            name="initialRentalIncome"
                            min="0"
                            step="any"
                            defaultValue={0}
                            {...register('initialRentalIncome', {
                                valueAsNumber: true,
                            })}
                        />
                        <span>{errors.initialRentalIncome?.type === 'required' && <small>required</small>}</span>
                    </div>

                    <div className="input-group mb-20">
                        <label htmlFor="annualRentalEscalationPercent">Annual rental increase %</label>
                        <input
                            type="number"
                            id="annualRentalEscalationPercent"
                            name="annualRentalEscalationPercent"
                            min="0"
                            step="any"
                            defaultValue={3}
                            {...register('annualRentalEscalationPercent', {
                                valueAsNumber: true,
                            })}
                        />
                    </div>
                    <div className={'monthly-payments-container'}>
                        {' '}
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <h4 className={'m-10'}>Extra monthly repayments</h4>
                            <button
                                type={'button'}
                                onClick={openAdditionalMonthlyPaymentsModal}
                                className={'icon-button m-10'}
                                style={{
                                    fontWeight: 'normal',
                                    width: 30,
                                    height: 30,
                                    // border: '1px solid white',
                                }}
                            >
                                {' '}
                                +
                            </button>
                        </div>
                        {!!additionalPayments.length && (
                            <div className={'additional-payment-badge-container'}>
                                {additionalPayments.map((payment, index) => (
                                    <AdditionalPayment
                                        key={index}
                                        payment={payment}
                                        remove={() => {
                                            setAdditionalPayments(additionalPayments.filter((_, i) => i !== index));
                                        }}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                    <div className={'monthly-payments-container'}>
                        {' '}
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <h4 className={'m-10'}>Lump sum payments</h4>
                            <button
                                type={'button'}
                                onClick={openLumpSumPaymentsModal}
                                className={'icon-button m-10'}
                                style={{
                                    fontWeight: 'normal',
                                    width: 30,
                                    height: 30,
                                    // border: '1px solid white',
                                }}
                            >
                                {' '}
                                +
                            </button>
                        </div>
                        {!!lumpSumPayments.length && (
                            <div className={'additional-payment-badge-container'}>
                                {lumpSumPayments.map((payment, index) => {
                                    const amortizationScheduleMonth =
                                        analysisResult && analysisResult.amortizationSchedule.find((a) => a.month === payment.month);
                                    const paymentIsUnnecessary =
                                        !amortizationScheduleMonth ||
                                        (amortizationScheduleMonth && amortizationScheduleMonth.additionalPayment <= 0);
                                    return (
                                        <LumpSumPayment
                                            key={index}
                                            payment={payment}
                                            remove={() => {
                                                setLumpSumPayments(lumpSumPayments.filter((_, i) => i !== index));
                                            }}
                                            willNotBeNecessaryBecauseBondWillBePaidOff={paymentIsUnnecessary}
                                        />
                                    );
                                })}
                            </div>
                        )}
                    </div>
                    <Modal
                        isOpen={additionalMonthlyPaymentModalIsOpen}
                        onRequestClose={closeAdditionalMonthlyPaymentsModal}
                        style={customStyles}
                    >
                        <button className={'float-right icon-button'} onClick={closeAdditionalMonthlyPaymentsModal}>
                            x
                        </button>
                        <h2>Make additional monthly payment </h2>
                        <AddAdditionalPayment onSubmit={handleAdditionalPayment.bind(this)} />
                    </Modal>
                    <Modal isOpen={lumpSumPaymentModalIsOpen} onRequestClose={closeLumpSumPaymentsModal} style={customStyles}>
                        <button className={'float-right icon-button'} onClick={closeLumpSumPaymentsModal}>
                            x
                        </button>
                        <h2>Make Lump sum payment </h2>
                        <AddLumpSumPayment onSubmit={handleLumpSumPayment.bind(this)} />
                    </Modal>

                    <div className="input-group">
                        <button type="submit" className="primary">
                            Analyze
                        </button>
                    </div>
                </form>
                <div className="insights-card" ref={insightsRef}>
                    <div className="insights-card-header">
                        {resultsHeader && <div className={'text-center'}>{resultsHeader}</div>}
                        <div className="insights-card-body">
                            {!loading && (
                                <div>
                                    {analysisResult && (
                                        <div>
                                            <div
                                                className={'headliner'}
                                                style={{
                                                    display: 'flex',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        flex: 1,
                                                    }}
                                                    className={'card background-black m-10-mobile'}
                                                >
                                                    <div className={'mb-20'}>
                                                        <div
                                                            className={`${
                                                                lastMonthSummary.cumulativeCashFlow >= 0 ? 'positive' : 'negative'
                                                            } headliner-profit`}
                                                        >
                                                            <NumberFormat
                                                                value={Math.abs(lastMonthSummary.cumulativeCashFlow).toFixed(2)}
                                                                className=""
                                                                displayType={'text'}
                                                                thousandSeparator={true}
                                                                prefix={'R'}
                                                                renderText={(value, props) => (
                                                                    <div {...props}>
                                                                        <strong>{value}</strong>
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>

                                                        <div>
                                                            {lastMonthSummary.cumulativeCashFlow < 0 && (
                                                                <span className={'negative headliner-description'}>
                                                                    Total money lost after {lastMonthSummary.month} months
                                                                </span>
                                                            )}
                                                            {lastMonthSummary.cumulativeCashFlow > 0 && (
                                                                <span className={'positive headliner-description'}>
                                                                    Total profit after {lastMonthSummary.month} months
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className={'mb-10'}>
                                                        {analysisResult.pointOfPositiveCashFlow && (
                                                            <div>
                                                                <span className={'headliner-profit'}>
                                                                    <strong>{analysisResult.pointOfPositiveCashFlow}</strong>
                                                                </span>{' '}
                                                                <span>
                                                                    month{analysisResult.pointOfPositiveCashFlow > 1 && <span>s</span>}
                                                                </span>
                                                                <div className={'headliner-description'}>
                                                                    <span className={'positive'}>
                                                                        Until this property starts making a <strong>monthly</strong> profit
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {!analysisResult.pointOfPositiveCashFlow && (
                                                            <div className={'negative headliner-description'}>
                                                                This property will not make a monthly profit within {lastMonthSummary.month}{' '}
                                                                months.
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className={'mb-10'}>
                                                        {analysisResult.pointOfPositiveCumulativeCashFlow && (
                                                            <div>
                                                                <span className={`headliner-profit`}>
                                                                    <strong>{analysisResult.pointOfPositiveCumulativeCashFlow}</strong>
                                                                </span>{' '}
                                                                <span>
                                                                    month
                                                                    {analysisResult.pointOfPositiveCumulativeCashFlow > 1 && <span>s</span>}
                                                                </span>
                                                                <div
                                                                    style={{
                                                                        fontSize: 20,
                                                                    }}
                                                                >
                                                                    <span>
                                                                        <span className={'positive headliner-description'}>
                                                                            Until this property breaks even. At this point you will have
                                                                            made back all the money you put into it{' '}
                                                                        </span>
                                                                        <br />
                                                                        <span
                                                                            className={'positive'}
                                                                            style={{
                                                                                fontSize: 20,
                                                                            }}
                                                                        ></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {!analysisResult.pointOfPositiveCumulativeCashFlow && (
                                                            <div className={'negative headliner-description'}>
                                                                This property will not break even within {lastMonthSummary.month} months.
                                                                <br />
                                                                <br />
                                                                <span style={{fontSize: 14, color: '#e8e8e8'}}>
                                                                    <img src={'/light-bulb.png'} width={14} alt={'Light bulb emoji'} /> Here
                                                                    are some ideas to improve the cash flow of this property:
                                                                    <ul>
                                                                        <li>
                                                                            <span
                                                                                className={'pointer underline'}
                                                                                onClick={openAdditionalMonthlyPaymentsModal}
                                                                            >
                                                                                Try making additional monthly payments
                                                                            </span>{' '}
                                                                            on your bond to reduce interest. A little goes a long way!
                                                                        </li>
                                                                        <li>Consider raising the rental amount.</li>
                                                                        <li>Negotiate a better purchase price</li>
                                                                        <li>Negotiate a better interest rate</li>
                                                                        <li>Consider a different property with better cash flow. </li>
                                                                    </ul>
                                                                    {/*<br />*/}
                                                                    {/*Try making additional monthly payments on your bond to reduce interest*/}
                                                                    {/*or consider raising the rental amount. Alterntive, you can try to*/}
                                                                    {/*negotiate a better*/}
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    {/*<div>Total monthly payment: {analysisResult.totalMonthlyPayment}</div>*/}
                                                </div>
                                                <HeadlineSummary data={analysisResult} />
                                            </div>
                                            <h4 className={'section-header'}>Milestones</h4>
                                            <div className={'milestones'}>
                                                {analysisResult.milestoneSnapShots.map((milestone) => (
                                                    <MonthlySummary
                                                        key={milestone.month}
                                                        data={milestone}
                                                        toggleExpand={() => setExpandMilestoneCards(!expandMilestoneCards)}
                                                        expanded={expandMilestoneCards}
                                                    />
                                                ))}
                                            </div>

                                            <h4 className={'section-header mobile-hide'}>Cash Flow</h4>
                                            <div className={'card background-black m-20 mobile-hide'}>
                                                <CashflowChart
                                                    data={analysisResult.amortizationSchedule}
                                                    canvasContextId={'cashFlowChart'}
                                                />
                                            </div>

                                            <h4 className={'section-header'}>Amortization Schedule</h4>
                                            <div className={'card background-black m-20 m-10-mobile'}>
                                                <AmortizationSchedule schedule={analysisResult.amortizationSchedule} />
                                            </div>

                                            <h4 className={'section-header'}>Disclaimer</h4>
                                            <div
                                                className={'m-20 m-10-mobile'}
                                                style={{
                                                    fontSize: 13,
                                                }}
                                            >
                                                <div>
                                                    Please note that the numbers presented above are very rough approximations. A lot of
                                                    factor will influence the actual numbers, including market conditions, fluctuations in
                                                    interest rates, the fees charged by conveyancing attorneys, etc. This tool is meant to
                                                    give you a rough idea of what to expect. Assumptions/Estimates made in this analysis
                                                    include:
                                                    <ul>
                                                        <li>Interest rate is fixed for the entire duration of the loan</li>
                                                        <li>Occupancy rate is 100%. That is, the property will never be vacant</li>
                                                        <li>Levies and rates and taxes will increase by 6% annually</li>
                                                        <li>
                                                            Conveyancing fees are estimated using fees we deem to be reasonable. You will
                                                            most likely be charged a different amounts, but the estimates should be in the
                                                            right ball park.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {!analysisResult && (
                                        <div>
                                            <div className={'mobile-hide'}>
                                                Waiting on analysis... <br />
                                                <span style={{fontSize: 20}}>
                                                    Fill in the numbers on the left or choose a sample property below to analyse
                                                </span>
                                            </div>
                                            <br />
                                            <br />
                                            <div className={'mobile-hide'}>
                                                <UseExampleValues />
                                            </div>
                                        </div>
                                    )}{' '}
                                </div>
                            )}
                            {loading && (
                                <div
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div className="loader">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function AdditionalPayment({payment, remove, willNotBeNecessaryBecauseBondWillBePaidOff = false}) {
    return (
        <div
            style={{
                backgroundColor: willNotBeNecessaryBecauseBondWillBePaidOff ? 'rgb(84, 114, 211, 0.75)' : '#5472d3',
                margin: 10,
                borderRadius: 20,
                padding: '10px 20px',
                boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.5)',
                minWidth: '150px',
                maxWidth: '200px',
            }}
        >
            <div
                style={{
                    fontWeight: 'bold',
                    fontSize: 14,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <div
                    style={{
                        fontWeight: 'bold',
                        display: 'flex',
                        // alignItems: 'center',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                    }}
                >
                    <NumberFormat
                        value={payment.amount.toFixed(2)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'R'}
                        renderText={(value, props) => (
                            <span style={{flex: 1}} {...props}>
                                {value}
                            </span>
                        )}
                    />
                    <small style={{color: '#FFD700', fontSize: '0.6rem'}}>
                        {payment.month && (
                            <div>
                                In {payment.month} month{payment.month !== 1 && 's'}
                            </div>
                        )}
                    </small>
                </div>
                {willNotBeNecessaryBecauseBondWillBePaidOff && (
                    <label className="tooltip">
                        ⚠️
                        <input type="checkbox" />
                        <span>This payment won't be necessary because the bond will have already been paid off</span>
                    </label>
                )}
                <button
                    onClick={remove}
                    style={{
                        fontSize: 16,
                        padding: '5px',
                        width: 20,
                        height: 20,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '50%',
                        cursor: 'pointer',
                        boxShadow: '2px 2px 5px #555555',
                    }}
                >
                    x
                </button>
            </div>
        </div>
    );
}

function LumpSumPayment({payment, remove, willNotBeNecessaryBecauseBondWillBePaidOff = false}) {
    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <AdditionalPayment
                payment={payment}
                remove={remove}
                willNotBeNecessaryBecauseBondWillBePaidOff={willNotBeNecessaryBecauseBondWillBePaidOff}
            />
        </div>
    );
}

export default Calculator;
