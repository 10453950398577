import './App.css';
import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Calculator from './components/Calculator';
import sampleProperties from './sample-properties';
import Landing from './pages/Landing';

let isMobileRegexp = /android|iphone/i;
let isMobileDevice = isMobileRegexp.test(navigator.userAgent);

function App() {
    return (
        <>
            <h1 className={'title'}>
                <a style={{color: '#FFD700'}} href={'/landing'}>
                    RentRoy
                </a>
                : When will your rental property be profitable
            </h1>
            <BrowserRouter>
                <Routes>
                    {sampleProperties.map((property) => (
                        <Route
                            key={property.link}
                            path={property.link}
                            element={
                                <Calculator
                                    isMobileDevice={isMobileDevice}
                                    defaultValues={property.values}
                                    calculateImmediately={true}
                                    resultsHeader={property.resultsHeader}
                                />
                            }
                        />
                    ))}
                    <Route path="/landing" element={<Landing />} />
                    <Route path="/" element={<Calculator isMobileDevice={isMobileDevice} />} />
                    <Route path="*" element={<Landing />} />
                </Routes>{' '}
            </BrowserRouter>
        </>
    );
}

export default App;
